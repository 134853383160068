<template>
    <div class="main-contact">
        <div class="top">
            <h1>Contact Us</h1>
            <h2>Have any questions? We'd love to hear from you.</h2>
            <p></p>
        </div>
        <div class="bottom">
            <div class="leftpanel">
                <h4>Aforma Solutions LLC</h4>
                <h5>Federal and Local Government Contractor Company</h5>
            </div>
            <div class="centerpanel">
                <h4>EMAIL</h4>
                <h5>contact@aformasolutions.com</h5>
                <button class="btn-email" @click="showform">Email US</button>

                <div v-if="formstat" >
                    <Form  class="form-container" @complete="hideform" :atContact="true"/>
                </div>
            </div>
            <div class="rightpanel">
                <div class="phone">
                    <h4>PHONE</h4>
                    <h5>443-535-1328</h5>
                </div>
                <div class="social-media">

                </div>
                <div class="disclosure">
                    © 2023 by Aforma Solutions LLC.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Form from '@/components/Form.vue'
import { ref } from 'vue'

export default {
  components: { Form },

  setup() {
    const formstat = ref(false)

    const showform = () => { 
        formstat.value = true
    }

    const hideform = () => { 
        formstat.value = false
    }

    return { formstat, showform, hideform }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 700px) {
    .top{
        margin-top: 30px;
        padding: 10px;
    }
    .bottom{
        flex-direction: column;
        /* justify-content: space-around; */
        align-items: center;
        text-align: center;
        font-size: 20px;
    }

    .centerpanel{
        margin-top: 40px;
        margin-bottom: 70px;
    }

    .phone {
        margin-bottom: 40px;
    }

    .form-container {
        margin-top: -15vh;
        width: 95vw;
    }
}

@media only screen and (min-width: 700px) {
    .top {
        margin: 150px auto 50px auto;
    }
    .bottom{
        justify-content: space-around;
    }

    .leftpanel{
        width: 30vw;
    }

    .centerpanel{
        width: 30vw;
    }
    .centerpanel h5 {
        font-size: 15px;
    }

    .rightpanel{
        width: 15vw;
    }

    .form-container {
        margin-top: -30vh;
        margin-left: -20%;
        max-width: 900px;
    }
}

.main-contact {
    margin-bottom: 300px;
    display:flex;
    flex-direction: column;
}
.top {
    /* border: 1px solid black; */
    text-align: center;
}
.bottom {
    /* border: 1px solid black; */
    display: flex;
}
.btn-email {
    cursor: pointer;
}
.form-container {
    position:sticky;
    top: 0;
    left: 0;
    background-color: white   ;
    border: 5px solid orange;
    border-radius: 15px;
}

</style>