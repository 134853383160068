<template>
    <div id="home-top">
        <div class="line-1 lines">GOVERNMENT CONTRACTING SOLUTIONS</div>
        <div class="line-2 lines">Helping Hands to Meet All Government Needs</div>
        <Form class="form"/> 
    </div>
</template>

<script>
import Form from '@/components/Form.vue'

export default {
  components: { Form },
}
</script>

<style scoped>
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media only screen and (min-width: 900px) {
  .lines { 
    margin-left: 10vw; 
    min-width: 500px;
    max-width: 700px;
  }

  .line-1 { margin-top: 80px; }

  .line-2 {
    margin-top: 50px;
    font-size: 60px
  }

  .form { 
    margin: 10px 100px 10px auto;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .lines { 
    margin-left: 10vw; 
    max-width: 700px;
  }

  .line-1 { margin-top: 80px; }

  .line-2 {
    margin-top: 50px;
    font-size: 60px
  }
  .form {
    margin: 30px auto;
  }
}

@media only screen and (max-width: 700px) {
  .lines {text-align: center;}

  .line-1 { margin-top: 3vh; }

  .line-2 {
    margin-top: 5vh;
    font-size: 30px
  }

  .form { margin: 5vh 0px; }
}

.lines { 
  color: white; 
  animation: fadeIn 3s; 
}

.line-1 { 
  font-family: Verdana, Geneva, Tahoma, sans-serif; 
  transition: all 0.5s ease-in-out;
}

.line-2 { 
  font-family:Georgia, 'Times New Roman', Times, serif; 
  transition: all 0.5s ease-in-out;
}

.form {
  border: 1px solid black;
  background-color: transparent;
  animation: fadeIn 4s; 
  transition: all 3s ease;
}
</style>