<template>
    <div class="main-about">
        <div class="top-about">
            <div class="top-intro">
                At Aforma Solutions LLC, we provide simple solutions to our government customers to meet their everyday product and service needs.
            </div>
        </div>
        <div class="mid-about">
            <div class="mid-left">
                <div class="do-line0">WHAT WE CAN DO FOR YOU</div>
                <div class="service do-line1">IT SOLUTIONS</div>
                <div class="service do-line2">PROFESSIONAL SERVICES SOLUTIONS</div>
                <div class="service do-line3">PRODUCT PROCUREMENT SOLUTIONS</div>
            </div>
            <div class="mid-center">
                <img src="@/assets/owner.png" alt="">
                <div class="line1">Hannah Oh</div>
                <div class="line2">Owner</div>
            </div>
            <div class="mid-right">
                "We continuously strive to listen closely and repond effectively to all of our customers needs. Earning and maintaining your loyalty is our top priority."
            </div>
        </div>
        <div class="bottom-about">
            <router-link :to="{ name: 'about'}">
                <div class="about-link">Learn More</div>
            </router-link>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
@media only screen and (min-width: 1000px) {
    .top-about {
        margin: 70px 200px 50px auto;
        width: 35vw;
        font-size: 40px;
    }

    .mid-left{
        margin-right: 30px;
        width: 30vw;
        justify-content: center;
    }
    .do-line0{
        font-size: 30px;
        margin-bottom: 10px;
    }
    .service{
        margin-bottom: 40px;
        font-size: 30px;
    }

    img{
        width: auto;
        height: 60vh;
    }

    .mid-right {
        width: 30vw;
        padding: 20px;
        align-self: center;
        font-size: 40px;
    }
}

@media only screen and (max-width: 1000px) and (min-width: 700px) {
    .top-about {
        margin: 30px;
        text-align: center;
        font-size: 30px;
    }

    .mid-left{
        margin-top:30px;
        margin-right: 20px;
        justify-content: flex-start;
    }
    .do-line0{
        font-size: 25px;
        margin-bottom: 20px;
    }
    .service{
        font-size: 25px;
        margin-bottom: 25px;
    }
    img{
        width: auto;
        height: 50vh;
    }
    .mid-right {
        width: 30vw;
        padding: 20px;
        align-self: center;
        font-size: 25px;
    }
}

@media only screen and (max-width: 700px) {
    .top-about {
        margin: 20px;
        text-align: center;
        font-size: 30px;
    }
    .mid-about {
        flex-direction: column;
    }
    .mid-left{
        margin-top:30px;
        margin-right: 10px;
        justify-content: flex-start;
;
    }
    .do-line0{
        font-size: 20px;
        margin-bottom: 10px;
        text-align: center;
    }
    .service{
        font-size: 20px;
        margin-bottom: 15px;
    }
    .mid-center{
        align-self: center;
    }
    img{
        max-width: 300px;
        height: auto;
    }
    .mid-right {
        padding: 20px;
        text-align: center;
        font-size: 20px;
    }
}

.main-about {
    display: flex;
    flex-direction: column;
}

.top-about {
    /* width: 100vw; */
    display: flex;
}

.top-intro {
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    transition: all 2s ease-in-out;
}

.mid-about {
    /* border-top: 3px solid lightgrey; */
    padding: 30px;
    /* width: 100vw; */
    display: flex;
    justify-content: space-around;
}
.mid-left {
    display: flex;
    flex-direction: column;
    transition: all 2s ease-in-out;
}

.service {
    padding: 5px;
    border-radius: 5px;
    border: 2px solid gray;
    text-align: center;
    color: black;
    background-color: lightgray;
}

.mid-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
img {
    transition: all 2s ease-in-out;
}
.line1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 25px;
}

.bottom-about {
    /* width: 100vw; */
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 50px;
}
.about-link {
    padding: 20px;
    border: 2px solid rgb(135 122 114);
    background-color: white;
    border-radius: 10px;
    font-size: 30px;
    color: rgb(135 122 114);
    transition: all 0.5s ease-in-out;
}

.about-link:hover{
    padding: 22px;
    font-size: 35px;
    font-weight: bold;
    color: rgb(217 123 65);
}

a { text-decoration: none; }
</style>