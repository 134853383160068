<template>  
    <div class="overlay">
        <form class="popup" @submit.prevent="sendEmail($event)">
            <div class="modal-header">
                <div class="title">Review</div>
                <button type="button" @click="$emit('needReview')" class="btn-edit">Edit Message</button>
            </div>
            
            <div class="modal-body">
                <div class="toppart">Organization: {{ cname }}</div>
                <div class="midpart">
                    From: {{ email }} ({{ fname }} {{ lname }})
                </div>
                <div class="bottompart">
                    <textarea disabled>{{ question }}</textarea>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn-submit" type="submit" >Send Message</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: [
        'cname',
        'fname',
        'lname',
        'email',
        'phone',
        'question',
    ],
    data() {
        return {
            clear: false,
        }
    },

    methods: {
        sendEmail(event) {
            var teste = false
            this.$loadScript('https://smtpjs.com/v3/smtp.js')
            .then( () => {
                try {
                    window.Email && window.Email.send ({
                        SecureToken: 'b6b7801d-b066-4908-8d07-2a98a2513934',
                        To: 'contact@aformasolutions.com',
                        From: 'mailer@aformasolutions.com',
                        Subject: "[Sent From Homepage]: " + this.fname + " " + this.lname,
                        Body: `<html>
                                    <head>
                                        <style>
                                            .email { 
                                                display: flex
                                                flex-direction: column
                                            }
                                            p {
                                                white-space: pre-wrap;
                                            }
                                        </style>
                                    </head>
                                    <body>
                                        <div class="email">
                                            <p>Organization: ${this.cname}</P>
                                            <p>Sender Name: ${this.fname} ${this.lname}</p>
                                            <P>Sender Contact: ${this.email} ${this.phone}</P> 
                                            <p>${this.question}</p>
                                        </div>
                                    </body>
                               </html>` 
                    }).then(
                        message => alert( message == 'OK' ? "Thank you for contacting us. We will review your inquiry as soon as possible." : "Sorry, that didn't work. " + message)
                    ).then(
                        this.$emit('sent')
                    )
                } catch(e) {
                    console.log(e)
                }
            })
        }
    }, 
}
</script>

<style scoped>
@media only screen and (max-width: 700px) {
    .overlay {
        height: 120vh; 
    }
    .popup {
        margin-top: 30vh;
        width: 95vw;
    }
}

@media only screen and (min-width: 700px) {
    .overlay {
        height: 100vh; 
    }

    .popup {
        margin: 40vh auto ;
        width: 50vw;
    }
}

.overlay {
    top: 0;
    left: 0;
    width: 100vw;
    position: fixed;
    backdrop-filter: blur(7px);
}

.popup {
    padding:10px;
    display: flex;
    flex-direction: column;
    background: #afa3a3;
    opacity: 0.9;
    border: 3px solid black;
    border-radius: 10px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
}
.title {
    font-weight: bold;
    font-size: 30px;
}

.btn-edit{
    align-self: center;
    height: 30px;
    margin-right: 20px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid white;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.btn-edit:hover {
    border: 2px solid blue;
}

.modal-body {
    display: flex;
    flex-direction: column;
    border-top: 1px solid black;
    gap: 1em;
}
.toppart{
    margin-top: 10px;
    font-size: 20px;
    /* border: 1px solid black; */
}
.midpart{
    margin-top: 10px;
    font-size: 20px;
    /* border: 1px solid black; */
}
.bottompart{
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    white-space: pre-wrap;
    /* border: 1px solid black; */
}

.bottompart textarea {
    height: 10em;
    width: 100%;
    font-size: 17px;
}

.modal-footer {
    margin-top: auto;
    align-self: center;
}

.btn-submit {
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid white;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}
.btn-submit:hover {
    border: 2px solid blue;
}

</style>