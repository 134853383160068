<template>
    <div>
        <div>IT EQUIPMENT</div>
        <div>AUTOMATION EQUIPMENT</div>
        <div>OFFICE EQUIPMENT</div>
        <div>OFFICE FURNITURES</div>
    </div>
</template>

<script>

</script>

<style>

</style>