<template>
    <div class="about-main">
        <img src="@/assets/underconstruction.png" alt="">
    </div>
</template>

<script>

</script>

<style scoped>

img {
    width: auto;
    height: auto;
    max-width: 100vw;
}
</style>