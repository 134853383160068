<template>
    <nav class="navbar">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Comfortaa|Varela Round">

        <div class="navbar-child companyName">
            <div class="companyName-logo">
                <router-link :to="{ name: 'home'}">
                    <img src="@/assets/maze-logo-edited.svg" alt="">
                </router-link>
            </div>
            <div class="companyName-name">
                <div class="companyName-name-upper">
                    <div class="left">aforma</div>
                    <div class="right">solutions</div>
                </div>
                <hr class="line">
                <div class="sub">P R O D U C T S & S E R V I C E S</div>
            </div>
        </div>

        <div class="navbar-child menu">
            <router-link :to="{ name: 'home'}">
                <div class="home link" @click="moveto('home')">Home</div>
            </router-link> 
            <router-link :to="{ name: 'about'}">
                <div class="about link">About Us</div>
            </router-link>
            <!-- <router-link :to="{ name: 'service'}">
                <div class="service link">Services</div>
            </router-link> -->
            <router-link :to="{ name: 'contact'}">
                <div class="contact link" @click="moveto('contact')">Contact</div>     
            </router-link>
        </div>

    </nav>
</template>

<script>
export default {
    methods: {
        moveto(tourl) {
            this.$router.push('/').then(() => this.$router.push(`.${tourl}`) )
        }
    }
}
</script>

<style scoped>
@keyframes fadeIn {
  0% { opacity: .25; }
  100% { opacity: 1; }
}

@media only screen and (max-width: 700px) {
    .navbar {
        /* border: 2px solid rgb(109, 51, 70); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        overflow: hidden;
        position: fixed;
        background-color: black;
        width: 100vw;
        height: 125px;
    }

    .menu {
        align-items: center;
        margin-top: 16px;
        width: 100vw;
        animation: fadeIn 1.5s; 
    } 

    .link { font-size: 1.2rem; }
}

@media only screen and (min-width: 700px){
    .navbar {
        /* border: 2px solid white; */
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        position: fixed;
        padding-left: 1em;
        padding-right: 1em;
        margin: 0 8vw 0 5vw;
        background-color: black;
        width: 87vw;
        height: 100px;
    }

    .companyName { align-self: center; }

    .menu {
        align-items: flex-end;
        padding-bottom: 30px;
        width: 45vw;
    }   
    .link { font-size: 1rem; }
}
.navbar { z-index: 1; }
.navbar-child { display: flex; }

.companyName {
    display: flex;
    width: 370px;
    height: 85px;
    animation: fadeIn 1.5s; 
}

.companyName-logo {
    display:flex;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: flex-end;
}

img {
    width: 80px;
    height: 80px;
    transition: filter 1s;
}

img:active {
    filter: blur(5px);
    cursor: pointer;
}

.companyName-name {
    display:flex;
    flex-direction: column;
    width: 280px;
    margin-top: 10px;
}

.companyName-name-upper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 45px;
}

.left  {
    color: rgb(216 188 171);
    font-family: Comfortaa;
    font-size: 30px;
    font-weight: bold;
}

.right {
    color: rgb(217 123 65);
    font-family: Comfortaa;
    font-size: 30px;
    font-weight: bold;
}

.line {
    margin: 0px;
    padding: 0px;
    align-self: center;
    color: rgb(217 123 65);
    width: 240px;
    height: 1px;
}
.sub {
    padding-top: 5px;
    color: rgb(135 122 114);
    align-self: center;
    justify-content: center;
    font-family: 'Varela Round';
    font-size: 15px;
}

.menu {
    display: flex;
    justify-content: space-evenly;
}

.link { color: white; }

.link:hover {
    /* background-color: #c5adc4; */
    color: rgb(217 123 65);
    border-radius: 5px;
    transition: 0.3s ease;
}

.link:local-link{
    color: orange;
}

.link.current:link{ color: rgb(217 123 65); }

a { text-decoration: none; }

</style>