<template>
  <div class="main">
    <div ref="toppage" class="home-top" />
    <div class="home-intro">
      <Home/>
    </div>
  
    <div class="home-about">
      <About/>
    </div>

    <!-- <div class="home-service" >
      <Services/>
    </div> -->

    <div ref="contactpage" class="home-contact">
      <Contact/>
    </div>
  </div>
</template>

<script>
import Home from '@/components/Home.vue'
import About from '@/components/About.vue'
import Services from '@/components/Services.vue'
import Contact from '@/components/Contact.vue'
import { useRoute } from 'vue-router'
import { ref, watch, onMounted } from 'vue'

export default {
  name: 'home',

  components: { Home, About, Services, Contact },

  setup(props, {refs}) {
    const toppage = ref(null)
    const contactpage = ref(null)
    const route = useRoute()

    onMounted(() => {
      if(route.name == 'contact'){
        contactpage.value?.scrollIntoView({behavior:"instant"})
      }
    }),

    watch(route, async() => {
      if(route.name == 'home'){
        toppage.value?.scrollIntoView({behavior:"instant"})
      }
      else if(route.name == 'contact'){
        contactpage.value?.scrollIntoView({behavior:"instant"})
      }
    })

    return { toppage, contactpage }
  },


  watch: {

  }
}
</script>

<style scoped>
@media only screen and (max-width: 700px) {
  .home-top{ height: 125px; }
}

@media only screen and (min-width: 700px) {
  .home-top{ height: 100px; }
}

.main { 
  display: flex;
  flex-direction: column;
}

.home-top { background-color: black; }

.home-intro {
  /* height: 100vh; */
  background-image: linear-gradient(to bottom right, #d97b1a, #d8bcab, #877a72);
}

.home-about {
  /* height: 100vh; */
  background-color: white;
}

.home-service {
  height: 100vh;
  background-color: #d97b1a;
}

.home-contact {
  /* height: 110vh; */
  background-color: #d8bcab;
}

</style>