<template>
    <div class='uc'>
        <img src="../assets/underconstruction.png" alt="">
    </div>
</template>

<script>

</script>

<style scoped>
.uc img {
    max-width: 100%;
    min-width: 300px;
    height: auto;
}
</style>