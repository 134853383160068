<template>
    <div class="form-container">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat">
        <div class="top-greeting">
            <div class="greeting">Please share your thoughts with us.</div>
            <button class="btn-cancel" v-if="atContact" @click="complete">Close</button>
        </div>
        <form @submit.prevent="showpopup" class="mainForm">
            <div class="cname">
                <div>Organization Name</div>
                <input class="cninput" type="text" v-model="cname">
            </div>
            
            <div class="name">
                <div class="fname">
                    <div>First name*</div>
                    <input class="fninput" type="text" required v-model="fname">
                </div>
                <div class="lname">
                    <div>Last name*</div>
                    <input class="lninput" type="text" required v-model="lname">
                </div>
            </div>

            <div class="contact-info">
                <div class="email">
                    <div>Email*</div>
                    <input class="emailinput" type="email" required v-model="email">
                </div>
                <div class="phone">
                    <div>Phone</div>
                    <input class="phoneinput" type="tel" v-model="phone">
                </div>
            </div>

            <div class="msg">
                <div>Inquiry*</div>
                <textarea class="qinput" type="text" required v-model="question"></textarea>
            </div>
            <div class="reviewform">
                <button class="form-button">Review</button>
            </div>
        </form>

        <Review v-if="review" :cname="cname" :fname="fname" 
                              :lname="lname" :email="email" 
                              :phone="phone" :question="question" 
                              @needReview="hidepopup"
                              @sent="complete"/>
    </div>
</template>

<script>
import Review from '@/components/Review.vue'
import {ref} from 'vue'

export default {
    props: { atContact: {
        type: Boolean,
        default: false
    } },

    components: { Review },

    setup(prop, {emit}) {
        const cname = ref('')
        const fname = ref('')
        const lname = ref('')
        const email = ref('')
        const phone = ref('')
        const question = ref('')

        var review = ref('')
        review.value = false

        const showpopup = () => { review.value = true }
        const hidepopup = () => { review.value = false }
        const complete = () => { 
            cname.value = ''
            fname.value = ''
            lname.value = ''
            email.value = ''
            phone.value = ''
            question.value = ''
            review.value = false
            emit('complete')
        }
  
        return { cname, fname, lname, email, phone, question, review, showpopup, hidepopup, complete }
    },
}
</script>

<style scoped>
@media only screen and (max-width: 700px) {
    .form-container {
        padding: 10px;
        }
    .btn-cancel{
        margin-right: 10px;
    }
    .name, .contact-info {
        flex-direction: column;
        width: 97%;
    }
    .fname, .lname, .email, .phone {
        width: 100%;
    }
}

@media only screen and (min-width: 700px) {
    .form-container {
        padding: 15px;
        width: 600px;
        height: 480px;
    }
    .btn-cancel{
        margin-right: 40px;;
    }
    .name, .contact-info {
        justify-content: space-between;
        width: 100%;
    }

    .fname, .lname, .email, .phone {
        width: 45%;
    }
}
.top-greeting {
    display: flex;
    justify-items: space-between;
}
.btn-cancel {
    height: 30px;
    width: 100px;
    margin-left: auto;
    font-size: 15px;
}
.mainForm {
    margin-top: 30px;
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
}

input, textarea {
    background-color: transparent;
    outline: 0;
    border-width: 0 0 2px;
    border-bottom: 1px solid black;
    font-size: 16px;
}

input:focus, textarea:focus {
    border-bottom: 3px solid black;
}

.cname:focus {
    font-weight: bold;
}
.cname {
    width: 100%;
    height: 60px;
}

.cninput {
    margin-top: 15px;
    width: 97%;
} 

.name, .contact-info {
    display: flex;

}

.fname, .lname, .email, .phone {
    margin-top: 15px;
    margin-right: 20px;
    height: 60px;  
}

.fninput, .lninput, .emailinput, .phoneinput {
    margin-top: 15px;
    width: 100%;
}

.msg {
    margin-top: 15px;
    width: 95%;
    height: 100px;
}

.qinput {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
}

.reviewform {
    display: flex;
    justify-content: center;
    width: 100%;
}

.form-button {
    width: 50%;
    margin-top: 70px;
    background-color: transparent;
    height: 30px;
    font-size: 15px;
    outline: 0;
    border-width: 0 0 2px;
    border: 1px solid black;
    cursor: pointer;
}

.form-button:hover {
    border: 2px solid black;
    font-weight: bold;
}
</style>