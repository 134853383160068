<template>
  <NavSection/>
  <router-view/>
</template>

<script>
import NavSection from "@/components/NavSection.vue"

export default {
  name: 'navSection',

  components: { NavSection }
}
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
